<template>
  <main class="page-content" id="js-page-content" role="main">
    <div class="subheader hidden-lg-up">
      <portal to="ariane">
        <MultipleListCreate></MultipleListCreate>
      </portal>
      <portal-target name="ariane"></portal-target>
    </div>

    <div class="w-100 bg-white rounded">
      <PostsFilters />
    </div>

    <div
      class="w-full d-flex justify-center flex-wrap lg:flex-row xl:flex-row py-4 overflow-hidden"
      v-if="loading === false"
    >
      <div
        class="column-grey col-ms col-md-4 col-lg-6 col-xl m-2 p-0.5 rounded-sm min-w-1/6"
        :id="`col-${statusIndex}`"
        v-for="(col, statusIndex) in attributes[this.formTypeSelected]"
        :key="statusIndex"
        @drop="dropHandler($event, statusIndex)"
        @dragover="dragoverHandler($event, statusIndex)"
        :class="{ 'drag-over': isDragActive && columnActive === statusIndex }"
      >
        <div class="d-flex justify-center items-center h-10">
          <h4 class="text-primary-900">{{ col.status.name }}</h4>
          <span
            class="mt-0.5 ml-1 inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-blue-100 text-blue-800"
          >
            <i
              v-if="
                isLoadingStatus === true && statusIndex === statusIndexClicked
              "
              class="fas fa-circle-notch fa-spin ml-2 text-black"
            ></i>
            <p v-else>{{ col.posts.length }}</p>
          </span>
        </div>
        <hr class="bg-primary" />
        <div class="panel d-none"></div>
        <div
          v-for="(post, index) in col.posts"
          :key="post.id"
          draggable="true"
          @dragstart="startDrag($event, index, statusIndex)"
          @dragend="endDrag($event)"
          class="card"
        >
          <PanelKanban :post="post" :id="`panel-id-${post.id}`"> </PanelKanban>
        </div>
      </div>
    </div>
  </main>
</template>

<style scoped>
.page-content {
  background-color: white;
}
.column-grey {
  background-color: #edf2f9;
  padding-bottom: 5%;
  border: dashed 1px transparent;
}
.row {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
}
.card {
  border: 0;
  cursor: grab;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}
.card:active {
  cursor: grabbing;
}
.card.dragging {
  opacity: 0.5;
  transform: scale(0.8);
}
.column-grey.drag-over {
  border: dashed 1px #0e7490;
}
</style>

<script>
import Panel from '../../components/UI/Panel'
import PanelKanban from '../../components/UI/PanelKanban'
import MultipleListCreate from '@/components/UI/MultipleListCreate'
import { mapActions, mapState } from 'vuex'
import PostsFilters from '@/components/Model/Post/PostsFilters'

export default {
  name: 'Kanban',
  components: {
    Panel,
    PanelKanban,
    MultipleListCreate,
    PostsFilters,
  },
  data() {
    return {
      loading: true,
      attributes: {},
      postNumber: '',
      isLoadingStatus: false,
      formTypeSelected: 1,
      statusIndexClicked: '',
      isDragActive: false,
      columnActive: -1,
    }
  },
  created() {
    this.doGetStatuses()
  },
  computed: {
    ...mapState('post', {
      posts: (state) => state.posts,
    }),
    ...mapState('status', ['formTypeStatuses']),
    config: {
      get() {
        return this.$store.state.post.postsConfig
      },
      set(value) {
        this.$store.commit('post/setPostsConfig', value)
      },
    },
  },
  watch: {
    posts: {
      handler(newValue, oldValue) {
        this.loading = true
        this.attributes[this.formTypeSelected] = {}
        this.formTypeStatuses[this.formTypeSelected].forEach((status) => {
          this.attributes[this.formTypeSelected][status.id] = {
            status: status,
            posts: [],
          }
        })
        this.posts.data.forEach((post) => {
          this.attributes[post.form.form_type_id][post.status_id].posts.push(
            post
          )
        })
        this.loading = false
      },
    },
  },
  methods: {
    ...mapActions('post', ['getPosts', 'updateStatus']),
    ...mapActions('status', ['getStatus']),
    selectColbyStatus(statusIndex) {
      return this.attributes[this.formTypeSelected][statusIndex]
    },
    startDrag(evt, index, statusIndex) {
      evt.dataTransfer.setData('statusIndexStart', statusIndex)
      evt.dataTransfer.setData('postIndexStart', index)
      evt.dataTransfer.dropEffect = 'move'
      evt.dataTransfer.effectAllowed = 'move'
      evt.currentTarget.classList.add('dragging')
      this.isDragActive = true
    },
    endDrag(evt) {
      evt.currentTarget.classList.remove('dragging')
      this.isDragActive = false
    },
    dragoverHandler(evt, statusIndex) {
      evt.preventDefault()
      evt.dataTransfer.dropEffect = 'move'
      this.columnActive = statusIndex
    },
    dropHandler(evt, statusIndex) {
      evt.preventDefault()

      let postIndexStart = evt.dataTransfer.getData('postIndexStart')
      let statusIndexStart = evt.dataTransfer.getData('statusIndexStart')
      this.statusIndexClicked = statusIndex

      let sourceColumn = this.selectColbyStatus(statusIndexStart)
      let targetColumn = this.selectColbyStatus(statusIndex)
      let post = sourceColumn.posts[postIndexStart]
      let status = targetColumn.status
      let postsSource = sourceColumn.posts
      let postsTarget = targetColumn.posts
      if (this.isLoadingStatus === true || statusIndexStart === statusIndex) {
        return
      }
      var updatedStatus = {
        id: post.id,
        status_id: status.id,
      }
      this.isLoadingStatus = true
      this.updateStatus(updatedStatus).then(() => {
        post.status_id = updatedStatus.status_id
        post.status = status
        postsSource.splice(postIndexStart, 1)
        postsTarget.push(post)

        this.attributes = JSON.parse(JSON.stringify(this.attributes))

        this.isLoadingStatus = false
      })
    },
    doGetStatuses() {
      this.config.include = 'medias,form,status,company,createdBy'
      this.getStatus()
        .then(() => {
          this.getPosts()
            .then(() => {
              this.attributes[this.formTypeSelected] = {}
              this.formTypeStatuses[this.formTypeSelected].forEach((status) => {
                this.attributes[this.formTypeSelected][status.id] = {
                  status: status,
                  posts: [],
                }
              })
              this.posts.data.forEach((post) => {
                this.attributes[post.form.form_type_id][
                  post.status_id
                ].posts.push(post)
              })
            })
            .catch((error) => {
              this.$store.dispatch('dispatchError', error)
            })
            .finally(() => {
              this.loading = false
            })
        })
        .catch((error) => {
          this.$store.dispatch('dispatchError', error)
        })
    },
  },
}
</script>
